import React from "react";
import Page from "./page";
import SideBar from "@components/side_bar";
import company_page from "@config/sidebars/pages/company_page";
export default function CompanyLayout({ children }) {
    return (
        <Page showsSearchBar title="Fyrirtækið">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-3 mb-3 order-2">
                        <SideBar menu={company_page} />
                    </div>
                    <div className="col-12 col-md-9 col-lg-9 col-xl-7 order-1 order-md-2">
                        {children}
                    </div>
                </div>
            </div>
        </Page>
    );
}
