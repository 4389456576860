import React from "react";
import { useState } from "react";
import classNames from "classnames";

import { Container, Title } from "@components/ui";
import Footer from "./footer";
import PageHeader from "./header";
import dynamic from "next/dynamic";
import Head from "next/head";
import { WEBPAGE_TITLE } from "@utils/constants";
import { FlexContainer } from "@components/ui/flex_container";

const SideMenu = dynamic(() => import("./menu"));

function Page({
    children,
    title,
    showsSearchBar,
    headTitle,
    headDescription,
    headImageUrl,
    headUrl,
    backgroundColor,
    showsFooter = true,
    searchQuery,
}) {
    const [showMenu, setShowMenu] = useState(false);
    let headerTitle = "";
    if (headTitle) {
        headerTitle = headTitle + " - " + WEBPAGE_TITLE;
    } else if (title) {
        headerTitle = title + " - " + WEBPAGE_TITLE;
    } else {
        headerTitle = WEBPAGE_TITLE;
    }
    return (
        <>
            <Head>
                <title key="site_title">{headerTitle}</title>
                {headImageUrl && <meta property="og:image" content={headImageUrl} key="og:image" />}

                {headerTitle && <meta property="og:title" content={headerTitle} key="og:title" />}
                {headUrl && <meta property="og:url" content={headUrl} key="og:url" />}
                {headDescription && (
                    <meta
                        property="og:description"
                        content={headDescription}
                        key="og:description"
                    />
                )}
                <link rel="canonical" href={headUrl} key="canonical-link" />
            </Head>
            <div className={"page"} style={{ backgroundColor }}>
                <SideMenu
                    show={showMenu}
                    onClose={() => setShowMenu(false)}
                    animation={classNames({ "slide-in-right": showMenu })}
                />

                <PageHeader
                    searchQuery={searchQuery}
                    height={65}
                    showsSearchBar={showsSearchBar}
                    onMenuClick={() => setShowMenu(true)}
                />

                <div
                    id="page-content"
                    style={{ flexGrow: 1 }}
                    className="page-content torgid-background"
                >
                    <PageTitle title={title} />
                    <FlexContainer
                        justifyContent="center"
                        className="page-body"
                        style={{
                            backgroundColor: backgroundColor,
                            paddingLeft: "20px",
                            paddingRight: "20px",
                        }}
                    >
                        {children}
                    </FlexContainer>
                </div>
                <Footer showsNavigation={showsFooter} />
            </div>
        </>
    );
}

function PageTitle({ title }) {
    return (
        <Container>
            {title ? (
                <Title size={1} className="fw-extra text-blue" style={{ marginTop: 20 }}>
                    {title}
                </Title>
            ) : (
                <></>
            )}
        </Container>
    );
}

export default Page;
