import GetTranslatedStrings from "@helpers/GetTranslatedStrings";

class Route {
    constructor({ title, title_en, link, icon, isExternal = false, sub_pages, default_open }) {
        this.title = title;
        this.title_en = title_en;
        this.link = link;
        this.icon = icon;
        this.isExternal = isExternal;
        this.sub_pages = sub_pages;
        this.default_open = default_open;
    }
    addSubPages(...pages) {
        this.sub_pages = pages;
        return this;
    }
}

export const ADGANGUR = new Route({
    title: "Minn aðgangur",
    title_en: "My Account",
    link: "/mitt/notandi",
    icon: "/img/sidebar/user.png",
});

export const SKRANING = new Route({
    title: "Skráning hjá 1819",
    title_en: "1819 Registration",
    link: "/mitt/skraning",
    icon: "/img/sidebar/registration.png",
});

export const ANSWERING_COMPANY_STATS = new Route({
    title: "Tölfræði svörunar",
    title_en: "Answering Statistics",
    link: "/mitt/simsvorun/tolfraedi",
    icon: "/img/sidebar/bar-chart.png",
});

export const UTSKRA = new Route({
    title: "Útskrá",
    title_en: "Log Out",
    link: "/logout",
    icon: "/img/sidebar/log-out.png",
});

export const THJONUSTUVER = new Route({
    title: "Þjónustuver",
    title_en: "Call Center",
    default_open: true,
    icon: "/img/sidebar/call-center.png",
});

export const THJONUSTUSIMI = new Route({
    title: "Þjónustusími",
    title_en: "Service Phone",
    link: "/mitt/simi/simsvorun",
    icon: "/img/sidebar/incoming-call.png",
    isExternal: true,
});

export const THJODSKRA = new Route({
    title: "Þjóðskrá",
    title_en: "National Registry",
    link: "/mitt/thjonustuver/thjodskra",
    icon: "/img/sidebar/thjodskra.png",
});

export const FYRIRTAEKI = new Route({
    title: "Fyrirtæki",
    title_en: "Companies",
    icon: "/img/sidebar/companies.png",
});

export const LEITARORD = new Route({
    title: "Leitarorð",
    title_en: "Keywords",
    link: "/mitt/fyrirtaeki/leitarord",
});

export const LISTAVINNSLA = new Route({
    title: "Listavinnsla",
    title_en: "Phone Lists",
    link: "/mitt/fyrirtaeki/listavinnsla",
    icon: "/img/sidebar/list-process.png",
});

export const SIMSVORUN_FYRIRTAEKJA = new Route({
    title: "Handrit fyrirtækja",
    title_en: "Company Scripts",
    link: "/mitt/thjonustuver/simsvorun",
    icon: "/img/sidebar/script.png",
});

export const SMS_GATT = new Route({
    title: "SMS gátt",
    title_en: "SMS Gateway",
    link: "/mitt/sms",
    icon: "/img/sidebar/sms.png",
});

export const SMS_GATT_SKRANING = new Route({
    title: "Skráning í SMS gátt",
    title_en: "SMS Gateway Registration",
    link: "/mitt/sms-skraning",
    icon: "/img/sidebar/sms-registration.png",
});

export const SMS_GATT_PARENT = new Route({
    title: "SMS gátt",
    title_en: "SMS Gateway",
    icon: "/img/sidebar/sms.png",
    default_open: true,
});

export const MARKADSMAL = new Route({
    title: "Markaðsmál",
    icon: "/img/sidebar/marketing.png",
});

export const AUGLYSINGAR = new Route({
    title: "Auglýsingar",
    link: "/mitt/auglysingar",
    icon: "/img/sidebar/ad.png",
});

export const PAKKAR = new Route({
    title: "Pakkar",
    title_en: "Packages",
    icon: "/img/sidebar/subscription.png",
    link: "/mitt/pakkar",
});

export const SKRIFSTOFA = new Route({
    title: "Skrifstofa",
    title_en: "Office",
    default_open: true,
    icon: "/img/sidebar/office.png",
});

export const STILLINGAR = new Route({
    title: "Stillingar",
    title_en: "Settings",
    link: "/mitt/stillingar",
    icon: "/img/sidebar/settings.png",
});

export const SVAR = new Route({
    title: "Svar",
    link: "/mitt/skrifstofa/svar",
    icon: "/img/sidebar/svar.png",
});

export const REIKNINGAR_SMS = new Route({
    title: "Reikningar SMS",
    title_en: "SMS Billing",
    link: "/mitt/skrifstofa/reikningar/sms",
    icon: "/img/sidebar/sms-bill.png",
});

export const REIKNINGAR_API = new Route({
    title: "Reikningar API",
    title_en: "API Billing",
    link: "/mitt/skrifstofa/reikningar/api",
    icon: "/img/sidebar/api-bill.png",
});

export const REIKNINGAR_SVARA = new Route({
    title: "Reikningar Svörun",
    title_en: "External Answering Billing",
    link: "/mitt/skrifstofa/reikningar/svara",
    icon: "/img/sidebar/invoice.png",
});

export const REIKNINGAR_NETSPJALL = new Route({
    title: "Reikningar Netspjall",
    title_en: "Chat Billing",
    link: "/mitt/skrifstofa/reikningar/netspjall",
    icon: "/img/sidebar/chat-box.png",
});

export const TOLFRAEDI = new Route({
    title: "Tölfræði",
    title_en: "Statistics",
    link: "/mitt/skrifstofa/tolfraedi",
    icon: "/img/sidebar/bar-chart.png",
});

export const REIKNINGAR_THJONUSTUVER = new Route({
    title: "Reikningar Þjónustuver",
    title_en: "Call Center Billing",
    link: "/mitt/skrifstofa/reikningar/thjonustuver",
    icon: "/img/sidebar/bill.png",
});

export const GAGNAGRUNNUR = new Route({
    title: "Gagnagrunnur",
    title_en: "Database",
    icon: "/img/sidebar/database.png",
});

export const ADGANGSSTYRING = new Route({
    title: "Aðgangsstýring",
    title_en: "Access Control",
    icon: "/img/sidebar/access-control.png",
});

export const HLUTVERK = new Route({
    title: "Hlutverk",
    title_en: "Roles",
    icon: "/img/sidebar/role.png",
    link: "/mitt/stillingar/hlutverk",
});
export const API_NOTENDUR = new Route({
    title: "API notendur",
    title_en: "API Clients",
    icon: "/img/sidebar/role.png",
    link: "/mitt/stillingar/api-notendur",
});

export const POSTNUMER = new Route({
    title: "Póstnúmer",
    title_en: "Postal Codes",
    link: "/mitt/stillingar/postnumer",
    icon: "/img/sidebar/mailbox.png",
});

export const STARFSFOLK = new Route({
    title: "Starfsfólk",
    title_en: "Employees",
    link: "/mitt/stillingar/starfsfolk",
    icon: "/img/sidebar/employees.png",
});

export const SMS_NOTENDUR = new Route({
    title: "SMS notendur",
    title_en: "SMS Users",
    link: "/mitt/sms/notendur",
    icon: "/img/sidebar/sms-user.png",
});

export const STARFSMANNAHANDBOK = new Route({
    title: "Starfsmannahandbók",
    title_en: "Employee Handbook",
    link: "/mitt/starfsmannahandbok",
    icon: "/img/sidebar/book.png",
});

export const HAGNYTAR_UPPLYSINGAR = new Route({
    title: "Hagnýtar upplýsingar",
    title_en: "Useful Information",
    link: "/mitt/hagnytar-upplysingar",
    icon: "/img/sidebar/book.png",
});

export const SIMTOL = new Route({
    title: "Símtöl",
    title_en: "Phone Calls",
    link: "/mitt/skrifstofa/simtol",
    icon: "/img/sidebar/phone-call.png",
});

export const SIMFELOGIN = new Route({
    title: "Símfélögin",
    title_en: "Phone Company Updates",
    link: "/mitt/gagnagrunnur/simfelogin",
    icon: "/img/sidebar/phone-company.png",
});

export const NY_FYRIRTAEKI = new Route({
    title: "Ný fyrirtæki",
    title_en: "New Companies",
    link: "/mitt/gagnagrunnur/ny-fyrirtaeki",
    icon: "/img/sidebar/new-company.png",
});

export const HEIMILISFANGABEIDNIR = new Route({
    title: "Heimilisfangabeiðnir",
    title_en: "Address Requests",
    link: "/mitt/gagnagrunnur/heimilisfong",
    icon: "/img/sidebar/address.png",
});

export const FYRIRTAEKJABEIDNIR = new Route({
    title: "Fyrirtækjabeiðnir",
    title_en: "Company Requests",
    link: "/mitt/gagnagrunnur/fyrirtaeki",
    icon: "/img/sidebar/add-company.png",
});

export const ATHUGASEMDIR_THJONUSTUVERS = new Route({
    title: "Athugasemdir þjónustuvers",
    title_en: "Call Center Comments",
    link: "/mitt/thjonustuver/athugasemdir",
    icon: "/img/sidebar/comment.png",
});
